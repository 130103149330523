<template>
	<div
		class="feed-logs"
		v-infinite-scroll="loadData"
		infinite-scroll-disabled="disabled">
		<div v-for="(log, index) in logs" class="feed-log">
			<div>{{log.createdAt|formatDate('YYYY-MM-DD HH:mm')}} {{log.createdBy.name}} {{log.actionDisplay}}</div>
			<template v-if="log.action === 'UPDATE' && log.detail">
				<div>站点: {{log.detail.website}}</div>
				<div>渠道: {{log.detail.channel}}</div>
				<div>配置规则: {{log.detail.rule|value(feedProductRules)}}</div>
				<template v-if="log.detail.contentType === 'spu'">
					<v-clamp autoresize :max-lines="1">
						配置内容: {{log.detail.contentType|value(feedProductContentTypes)}}-{{log.detail.content}}
						<template #after="{ toggle, expanded, clamped }">
						    <a
						      href="#"
						      v-if="expanded || clamped"
						      class="ml-1 badge badge-light"
						      @click.prevent="toggle"
						    >{{ expanded ? '收起' : '展开' }}</a>
						</template>
					</v-clamp>
				</template>
				<template v-else>
					<div>配置内容: {{log.detail.contentType|value(feedProductContentTypes)}}</div>
					<v-clamp autoresize :max-lines="1">
						{{log.detail.content}}
						<template #after="{ toggle, expanded, clamped }">
						    <a
						      href="#"
						      v-if="expanded || clamped"
						      class="ml-1 badge badge-light"
						      @click.prevent="toggle"
						    >{{ expanded ? '收起' : '展开' }}</a>
						</template>
					</v-clamp>
				</template>
			</template>
		</div>
		<div class="h-100" :class="{'d-none': !loading}" v-loading="loading" element-loading-text="加载中..."></div>
		<div v-if="loadMore" class="text-center text-secondary form-control-sm">加载更多...</div>
		<div v-if="noMore" class="text-center text-secondary form-control-sm border-top">我是有底线的</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import VClamp from 'vue-clamp'
	export default {
		components: {
			VClamp
		},
		props: {
			item: {
				type: Object,
				default: () => {}
			},
			allCategories: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				loading: false,
				logs: [],
				totalCount: 0,
				loadMore: false,
				noMore: false,
				namespace: 'feeds-content-product'
			}
		},
		computed: {
			...mapGetters({
				feedProductRules: 'feeds-content-product/rules',
				feedProductContentTypes: 'feeds-content-product/contentTypes'
			}),
			disabled() {
				return this.loading || this.noMore || this.loadMore
			},
			currentPage() {
				return Math.floor(this.logs.length / 20) + 1
			},
			productCategories() {
				return this.allCategories[this.item.website] || []
			}
		},
		filters: {
			actionValue(action) {
				switch (action) {
					case 'publish':
						return '发布了新配置:'
					case 'open':
						return '开启配置'
					case 'close':
						return '关闭配置'
					default:
						return action
				}
			}
		},
		mounted() {
			this.loadData()
		},
		methods: {
			loadData() {
				if (this.loading || this.loadMore) return
				if (this.logs.length === 0) {
					this.loading = true
				} else {
					this.loadMore = true
				}
				const { id } = this.item
				const params = { current: this.currentPage, size: 20, feedConfigId: id }
				this.$store.dispatch(`${this.namespace}/getLogs`, params)
					.then(({totalCount, content}) => {
						this.totalCount = totalCount
						const logs = content.map(v => {
							let detail = null
							try { 
								detail = JSON.parse(v.body)
								if (detail.type === 'CATEGORY') {
									const flatMapCategories = (array) => {
										return array.reduce((lhs, rhs) => {
											const res = lhs.concat([rhs])
											if (Array.isArray(rhs.children)) {
												return res.concat(flatMapCategories(rhs.children))
											}
											return res
										}, [])
									}
									const flatMapedCategories = flatMapCategories(this.productCategories)
									content = detail.content.split(',').map(id => flatMapedCategories.find(v => v.id === id)).filter(v => v !== null).map(v => v.completeName).join(',')
									detail = {...detail, content}
								}
							} catch (e) {}
							return {...v, detail}
						})
						console.log(logs)
						this.logs = this.logs.concat(logs)
						this.noMore = this.logs.length >= this.totalCount
						this.loading = false
						this.loadMore = false
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.loading = false
						this.loadMore = false
					})
			}
		}
	}
</script>

<style lang="scss">
	.feed-logs {
		padding: 10px;
		overflow-y: auto;
		height: 100%;

		.feed-log {
			margin-bottom: 20px;
			padding: 15px 10px;
			font-size: 14px;
			border: 1px solid #ddd;
			border-radius: 3px;
		}
	}
</style>
