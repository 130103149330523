<template>
	<el-card>
		<template v-if="hasErr">
			<el-result v-if="hasErr" icon="error" title="错误提示" subTitle="网络错误,请稍后重试">
				<template slot="extra">
					<el-button type="primary" size="medium" @click="__init()">刷新</el-button>
				</template>
			</el-result>
		</template>
		<template v-else>
			<advanced-search-bar>
				<template>
					<form-item-sites ref="form-item-sites" :abbr="true" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;__init()" :clearable="false"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="editRow(null)">添加配置</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="渠道:">
						<el-select v-model="queryForm.channel" class="w-100" size="mini">
							<el-option v-for="item in feedProductChannels" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="配置规则:">
						<el-select v-model="queryForm.rule" size="mini" clearable>
							<el-option v-for="item in feedProductRules" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="配置内容:">
						<el-select v-model="queryForm.type" size="mini" clearable>
							<el-option v-for="item in feedProductContentTypes" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="配置状态:">
						<el-select v-model="queryForm.status" size="mini" clearable>
							<el-option v-for="item in feedProductStates" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</form-item>
				</template>
			</advanced-search-bar>
			<el-table height="auto" :data="tableData">
				<el-table-column label="站点" prop="website" min-width="120">
				</el-table-column>
				<el-table-column label="渠道" prop="channel" min-width="120">
				</el-table-column>
				<el-table-column label="配置规则" min-width="120">
					<template slot-scope="scope">{{scope.row.rule|value(feedProductRules)}}</template>
				</el-table-column>
				<el-table-column label="配置内容" min-width="120" align="center">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="showDetail(scope.row)">{{scope.row.type|value(feedProductContentTypes)}}</el-button>
					</template>
				</el-table-column>
				<el-table-column label="配置状态" min-width="120" align="center">
					<template slot-scope="scope">
						<div :class="feedProductStatus(scope.row.status)">{{scope.row.status|value(feedProductStates)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="最后更新人" min-width="120">
					<template slot-scope="scope">
						<div v-if="scope.row.updatedBy">{{scope.row.updatedBy.name}}</div>
						<div v-else-if="scope.row.createdBy">{{scope.row.createdBy.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="最后更新时间" min-width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.updatedAt">
							{{scope.row.updatedAt|formatDate('YYYY-MM-DD HH:mm')}}
						</div>
						<div v-else-if="scope.row.createdAt">
							{{scope.row.createdAt|formatDate('YYYY-MM-DD HH:mm')}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="200">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status === 'DRAFT'" type="text" size="mini" @click="dbConfirm(scope.row, 'TEST')">发布测试</el-button>
						<el-button v-if="scope.row.status === 'TEST'" type="text" size="mini" @click="dbConfirm(scope.row, 'ONLINE')">发布正式</el-button>
						<el-button v-if="scope.row.status === 'DRAFT' || scope.row.status === 'TEST'" type="text" style="color:#333;" size="mini" @click="editRow(scope.row)">编辑</el-button>
						<el-button v-if="scope.row.status === 'DRAFT' || scope.row.status === 'TEST'" type="text" class="text-danger" size="mini" @click="dbConfirm(scope.row, 'ARCHIVED')">删除</el-button>
						<el-button v-if="scope.row.status === 'ONLINE' || scope.row.status === 'CLOSE'" type="text" size="mini" @click="showLogs(scope.row)">查看修改日志</el-button>
						<el-button v-if="scope.row.status === 'ONLINE'" class="text-danger" type="text" size="mini" @click="dbConfirm(scope.row, 'DISABLED')">关闭</el-button>
						<el-button v-if="scope.row.status === 'CLOSE'" class="text-success" type="text" size="mini" @click="dbConfirm(scope.row, 'ENABLED')">开启</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</template>
		<el-dialog :title="selectedItem && selectedItem.type === 'SPU' ? 'SPU' : '品类'" :visible.sync="visible.detail" :show-close="false">
			<div v-if="selectedItem">
				<div v-if="selectedItem.type === 'SPU'">
					<el-input
					  type="textarea"
					  :rows="4"
					  placeholder="Please input"
					  :value="selectedItem.content" 
					  readonly>
					</el-input>
				</div>
				<div v-else style="max-height: 300px;overflow-y:auto;" :class="{border: selectedItem.content.length > 0}">
					<div v-if="selectedItem.content.length === 0" class="text-center">No Data Matched</div>
					<div v-else v-for="item in selectedItem.content.slice((detailPage.current - 1) * detailPage.size, detailPage.current * detailPage.size)" class="border-bottom p-1 d-flex flex-row align-items-center justify-content-between">
						<small>{{item.id}}</small>
						<small>{{item.detail && item.detail.completeName}}</small>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer d-flex flex-row align-items-center justify-content-between">
				<div v-if="selectedItem">
					<el-pagination v-if="selectedItem.type === 'CATEGORY'"
						:hide-on-single-page="true"
						@current-change="detailPage.current=$event" :current-page.sync="detailPage.current" :page-size="detailPage.size"
						 layout="prev, pager, next" :total="selectedItem.content.length">
					</el-pagination>
				</div>
				<el-button type="primary" @click="visible.detail = false" size="mini">关 闭</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose" :title="form.id ? '编辑' : '添加'">
			<el-form ref="form" :model="form" label-width="80px" size="mini" :hide-required-asterisk="true" :rules="rules">
				<el-form-item label="站点" prop="website">
					<el-select v-model="form.website" class="w-100" @change="websiteChanged">
						<el-option v-for="item in websites" :label="item.key" :value="item.key"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="渠道" prop="channel">
					<el-select v-model="form.channel" class="w-100">
						<el-option v-for="item in feedProductChannels" :label="item.value" :value="item.key"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="配置规则" prop="rule">
					<el-select v-model="form.rule" class="w-100">
						<el-option v-for="item in feedProductRules" :label="item.value" :value="item.key"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="配置内容">
					<el-select v-model="form.type" @change="contentTypeChanged" class="w-100">
						<el-option v-for="item in feedProductContentTypes" :label="item.value" :value="item.key"></el-option>
					</el-select>
				</el-form-item>				
				<template v-if="form.type === 'CATEGORY'">
					<el-form-item label="商品品类" prop="content">
						<treeselect
							id="feed-content-tree"
							v-model="form.content"
							:multiple="true"
							:options="productCategories"
							placeholder=""
							search-nested
							value-consists-of="ALL"
						>
							<label slot="option-label" slot-scope="{ node }" style="transform:translateY(2px);">
								{{ node.label }}
							</label>
							<div slot="value-label" slot-scope="{ node }">{{ node.raw.completeName }}</div>
						</treeselect>
					</el-form-item>
				</template>
				<template v-else>
					<el-form-item label="spu" prop="content">
						<el-input type="textarea" :rows="4" v-model="form.content"></el-input>
					</el-form-item>
				</template>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</div>
		</el-dialog>
		<el-drawer
		  id="feed-product-logs"
		  :visible.sync="visible.log"
		  direction="rtl"
		  :size="400"
		  :before-close="handleClose">
		  <logs :item="selectedItem" :key="logsKey" :allCategories="allCategories"></logs>
		</el-drawer>
	</el-card>
</template>

<script>
	import Config from './minixs/index.vue'
	import {mapGetters} from 'vuex'
	import Treeselect from '@riophae/vue-treeselect'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import Logs from '@/components/feeds/content/logs.vue'
	import { uuid } from 'vue-uuid'
	import {fetchJSONByApis} from '@/apis'
	export default {
		components: { 
			Treeselect,
			Logs
		},
		mixins: [Config],
		data() {
			return {
				hasErr: false,
				queryForm: {
					website: '',
					channel: '',
					rule: '',
					type: '',
					status: ''
				},
				visible: {
					add: false,
					detail: false,
					log: false
				},
				tableData: [],
				selectedItem: undefined,
				websites: [],
				detailPage: {
					current: 1,
					size: 10
				},
				form: {
					website: '',
					channel: '',
					rule: '',
					type: 'CATEGORY',
					content: []
				},
				rules: {
					website: [{
						required: true,
						message: '站点不能为空'
					}],
					channel: [{
						required: true,
						message: '渠道不能为空'
					}],
					rule: [{
						required: true,
						message: '配置规则不能为空'
					}],
					content: [{
						required: true,
						message: '配置的相关内容不能为空'
					}]
				},
				logsKey: uuid.v4(),
				allCategories: {},
				namespace: 'feeds-content-product',
				feedProductChannels: []
			}
		},
		computed: {
			...mapGetters({
				feedProductRules: 'feeds-content-product/rules',
				feedProductStates: 'feeds-content-product/states',
				feedProductContentTypes: 'feeds-content-product/contentTypes'
			}),
			productCategories() {
				return this.allCategories[this.form.website] || []
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = {
						website: '',
						channel: '',
						rule: '',
						type: 'CATEGORY',
						content: []
					}
				}
			}
		},
		created() {
			this.loadChannels()
		},
		methods: {
			loadChannels() {
				this.$store.dispatch(`${this.namespace}/fetchChannels`)
				.then(res => {
					this.feedProductChannels = Object.keys(res).map(key => {
						return {
							key,
							value: res[key]
						}
					})
				})
				.catch(err => {
					console.log(err)
					setTimeout(() => {this.loadChannels()}, 2000)
				})
			},
			// Init
			__init() {
				console.log(this.queryForm.website)
				this.websites = this.$refs['form-item-sites'].websites
				this.hasErr = false
				this.adminLayout.showLoading()
				const apis = this.websites.map(({key}) => this.$store.dispatch('feeds-content-product/getCategories', {websiteCode: key}))
				fetchJSONByApis(...apis)
				.then(results => {
					const convert2Tree = (array) => {
						if (!Array.isArray(array)) return []
						return array.map(v => {
							const children = convert2Tree(v.childs)
							const _v = {
								...v,
								label: v.name
							}
							return children.length > 0 ? {..._v, children} : _v
						})
					}
					results.forEach((result,index) => {
						this.allCategories[this.websites[index].key] = convert2Tree(result)
					})
					this.getList()
				})
				.catch(error => {
					this.hasErr = true
					this.adminLayout.hideLoading()
				})
			},
			// Actions
			showDetail(row) {
				let content = row.content || ''
				const categories = this.allCategories[row.website] || []
				if (row.type === 'CATEGORY') {
					const flatMapCategories = (array) => {
						return array.reduce((lhs, rhs) => {
							const res = lhs.concat([rhs])
							if (Array.isArray(rhs.children)) {
								return res.concat(flatMapCategories(rhs.children))
							}
							return res
						}, [])
					}
					const flatMapedCategories = flatMapCategories(categories)
					content = content.split(',').map(id => {
						return  {
							id,
							detail: flatMapedCategories.find(v => v.id === id)
						}
					})
					this.detailPage.current = 1
				}
				this.selectedItem = {
					...row,
					content
				}
				this.visible.detail = true
			},
			feedProductStatus(key) {
				const item = this.feedProductStates.find(v => v.key === key)
				return item && `text-${item.status}` || ''
			},
			contentTypeChanged(val) {
				this.form.content = val === 'SPU' ? '' : []
			},
			editRow(row) {
				if (row) {
					this.form = {
						...row,
						content: row.type === 'SPU' ? row.content : row.content.split(',')
					}
				} else {
					this.form.website = this.queryForm.website
				}
				this.selectedItem = row
				this.visible.add = true
			},
			submitForm() {
				this.$refs.form.validate((v) => {
					if (v) {
						const action = this.form.id ? 'UPDATE' : 'NEW'
						let content = this.form.content
						if (this.form.type === 'SPU') {
							content = content.match(/(\d+)/g) || []
							if (content.length === 0) {
								this.$showErrMsg('spu输入不正确')
								return
							}
						}
						const body = {
							action,
							body: {
								...this.form,
								content
							},
							id: this.form.id || null
						}
						this.adminLayout.showLoading(true)
						this.$store.dispatch(`${this.namespace}/updateItem`, {...body})
						.then(res => {
							this.adminLayout.hideLoading(true)
							this.visible.add = false
							this.$showSuccess(action === 'NEW' ? '添加成功' : '更新成功')
							if (this.selectedItem) {
								Object.keys(this.selectedItem).forEach(key => {
									this.selectedItem[key] = res[key]
								})
							} else {
								this.getList()
							}
						})
						.catch(err => {
							this.$showErrMsg(err)
							this.adminLayout.hideLoading()
						})
					}
				})
			},
			doAction(action, id) {
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/updateItem`, {action, id})
				.then(res => {
					this.adminLayout.hideLoading()
					this.getList()
				})
				.catch(err => {
					this.$showErrMsg(err)
					this.adminLayout.hideLoading()
				})
			},
			dbConfirm(row, action) {
				switch (action) {
					case 'TEST':
					case 'ARCHIVED':
						this.$confirm(action === 'TEST' ? '是否发布到测试?' : '是否删除该条配置?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.doAction(action, row.id)
						})
						break
					case 'ENABLED':
					case 'DISABLED':
						this.$confirm(action === 'ENABLED' ? '是否开启该条配置?' : '是否关闭该条配置?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.doAction(action, row.id)
						})
						break
					case 'ONLINE':
						this.adminLayout.showLoading()
						const params = {website: row.website, channel: row.channel, status: ['ONLINE', 'CLOSE']}
						this.$store.dispatch(`${this.namespace}/getList`, {params})
						.then(res => {
							console.log(res)
							this.adminLayout.hideLoading()
							this.$confirm(res.totalCount === 0 ? '是否发布到正式？' : '已有相同条件配置，请确认是否更新原有配置？', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.doAction(action, row.id)
							})
						})
						.catch(err => {
							this.$showErrMsg(err)
							this.adminLayout.hideLoading()
						})
						break
				}
			},
			showLogs(row) {
				this.selectedItem = row
				this.logsKey = uuid.v4()
				this.$nextTick(() => {
					this.visible.log = true
				})
			},
			websiteChanged() {
				this.form.content = this.form.type === 'SPU' ? '' : []
			}
		}
	}
</script>

<style lang="scss">
	#feed-content-tree {
		.vue-treeselect__multi-value-item {
			border-radius: 10px;
			background-color: #f4f4f5;
			border: 1px solid #e9e9eb;
			color: #909399;
			span {
				font-size: 10px;
				line-height: 20px;
			}
		}
		.vue-treeselect__icon.vue-treeselect__value-remove {
			color: #909399;
		}
	}
	#feed-product-logs {
		.el-drawer__header {
			padding: 0;
			margin-bottom: 0;
			span {
				display: none;
			}
		}
	}
</style>
